<template>
    <div class="flex-col" style="height:100%">
      <div class="flex-col items-center" style="flex:1;padding: 3.25rem 1rem 0;background: #fff;overflow: auto;">
        <div style="font-size: 1rem;margin:1.25rem 0 3rem;font-weight: 700">{{DetailData.title}}</div>
        <div  class="used_rich">
          <div v-html="DetailData.content" style="font-size: 1rem"></div>
        </div>
      </div>
      <div class="flex-col section_9" style="padding-top: 1rem">
<!--        <div class="flex-row items-center space-x-4"  style="margin-bottom: 1.25rem">-->
<!--          <img-->
<!--              class="image_16"-->
<!--              :src="require('@/assets/logo.png')"-->
<!--          />-->
<!--          <img-->
<!--              class="image_17"-->
<!--              :src="require('@/assets/white_MAKAFIM.png')"-->
<!--          />-->
<!--        </div>-->
        <div style="display: flex">
          <div class="flex-col flex-auto" style="width: 70%">
            <span class="font_8 text_19">为跨境企业提供全方位、高效、安全的跨境金融服务</span>
            <el-button style="border-radius: 0.25rem;color:#161616;width:80%;margin-top: 1.625rem;font-size: 0.875rem;line-height:2.375;background-image: linear-gradient(90deg, #ffbe58 0%, #ff863d 100%);" @click="$router.push({path:'/home/apply'})">我要申请</el-button>
          </div>
          <img
              class="shrink-0 image_18"
              :src="require('@/assets/QR_code.png')"
          />
        </div>
        <div class=" image-wrapper" style="margin-top: 1rem">
          <div style="font-size:0.625rem;color:#FFFFFF">版权所有@麦嘉Maka<a class="aq" target="_blank" href="https://beian.miit.gov.cn">粤ICP备2023119718号-1</a></div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ManualDetail",
  data(){
    return {
      DetailData:{
        title:'',
        content:'',
      }
    }
  },
  mounted() {
   this.DetailData = this.$utils.CacheSessionGet('manual').find(item=>item.id===+this.$route.query.id)
  }
}
</script>
<style scoped>
.used_rich{
  font-size: 1rem;
  line-height: 2;
}
.used_rich ol,.used_rich ol li{
  list-style:decimal ;
}
.used_rich ul,.used_rich ul li{
  list-style:disc ;
}
.aq{
  margin-left: 1.5rem;
  color: #FFFFFF;
  text-decoration: none;
}
.aq:hover{
  text-decoration: underline;
}
</style>